import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper } from './../_helper/default';
import {
  API_INSERT_UPDATE_MASTER_DATA,
  API_GET_PLANTS,
  API_GET_USER_PLANTS,
  API_GET_COLLECTION_TYPES,
  API_GET_MINE_LIST,
  API_GET_MASTER_DATA_LIST,
  API_GET_MASTER_DATA_DELETE,
  API_GET_TRANSPORTER_LIST,
  API_GET_DEPT_LIST,
  API_GET_DESIGNATION_LIST,
  API_GET_PLANT_MINE_LIST,
  API_GET_FEEDER_LIST,
  GET_ALL_SAMPLE_PART_SIZE,
  API_GET_AUGER_BY_PLANT,
  GET_ALL_USER_CATEGORY,
  GET_ALL_USER_LIST,
  GET_ALL_MINE_BY_COLLECTION_TYPE,
  API_GET_ALL_PLANT_MINE_LIST,
  API_GET_TRANSPORTER_LISTING,
  API_POST_TRANSPORTER_INSERT,
  API_GET_TRANSPORTER_DELETE,
  GET_TYPE_LIST,
  INSERT_TYPE_MASTER,
  API_GET_ALL_PLANT_MINES,
  API_GET_ALL_DO_LIST,
  API_GET_STATUS_CHANGE,
  API_POST_DO_SAVE,
  API_DO_COLLECTION_TYPES,
  API_ALL_MINES_MASTER,
  API_GET_DO_LIST,
  API_GET_PLANT_MINE_DELETE,
  API_GET_LOCATION_LIST,
  API_INSERT_UPDATE_LOCATION_DATA,
  API_GET_CUSTOMERS,
  API_GET_RFID_MASTER_LIST,
  API_GET_ALL_SAMPLING_POINT
} from '../_common/constant';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(
    private helper: Helper,
    private http: HttpClient) { }

  // THIS FUNCTION IS USED TO SAVE / UPDATE ALL MASTER DATA //
  _saveMstData(mstData: any) {
    return this.http.post(API_INSERT_UPDATE_MASTER_DATA, mstData);
  }

  // FETCH MASTER DATA //
  _getMstList(queryParam: string) {
    return this.http.get(API_GET_MASTER_DATA_LIST + queryParam);
  }
  // DELETE MASTER DATA //
  _getMstDelete(queryParam: string) {
    return this.http.get(API_GET_MASTER_DATA_DELETE + queryParam);
  }

  // ALL PLANT LIST //
  _getAllPlant() {
    return this.http.get(API_GET_PLANTS);
  }

  // ALL MINE LIST UNDER PLANT //
  _getAllPlantMine(collectionTypeID: number) {
    return this.http.get(API_GET_PLANT_MINE_LIST + '?PlantID=' + this.helper.getSelectedPlant() + '&CollectionTypeID=' + collectionTypeID);
  }

   // ALL MINE BY COLLECTION TYPE //
   _getAllMineByCollectionType(collectionTypeID: number) {
    return this.http.get(GET_ALL_MINE_BY_COLLECTION_TYPE + '?PlantID=' + this.helper.getSelectedPlant() + '&CollectionTypeID=' + collectionTypeID);
  }

   // ALL MINE BY COLLECTION TYPE //
   _GetAllDoByPlantMineCollType(queryParam: string) {
    let url = API_GET_DO_LIST;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  _getAllPlantMineList(queryParam: string){
    return this.http.get(API_GET_ALL_PLANT_MINE_LIST + queryParam);
  }
  

  // ALL FEEDER LIST UNDER PLANT //
  _getAllFeeder() {
    return this.http.get(`${API_GET_FEEDER_LIST}?PlantID=${this.helper.getSelectedPlant()}`);
  }

  // ALL DEPARTMENT LIST //
  _getDeptList() {
    return this.http.get(API_GET_DEPT_LIST);
  }

  // ALL DESIGNATION LIST //
  _getDesignationList() {
    return this.http.get(API_GET_DESIGNATION_LIST);
  }

  // USER WISE PLANT LIST //
  _getPlantByUser() {
    return this.http.get(API_GET_USER_PLANTS);
  }

  // COLLECTION TYPE LIST //
  _getAllCollectionType(queryParam?: string) {
    let url = API_GET_COLLECTION_TYPES;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  // MINE LIST //
  _getAllMine(queryParam?: string) {
    let url = API_GET_MINE_LIST;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }
  
  // GET ALL MINE //
  getAllMine(){
    return this.http.get(`${API_GET_MINE_LIST}?PlantID=${this.helper.getSelectedPlant()}`);
  }

  // ALL TRANSPORTER LIST //
  _getAllTransporter(queryParam?: string) {
    let url = API_GET_TRANSPORTER_LIST;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  // TRANSPORTER LIST BY PLANT //
  _getTransporterByPlant() {
    return this.http.get(`${API_GET_TRANSPORTER_LIST}?PlantID=${this.helper.getSelectedPlant()}`);
  }

  // SAMPLE SIZE LIST //
  _sampleSizeList(toBeTested: number) {
    return this.http.get(`${GET_ALL_SAMPLE_PART_SIZE}?ToBeTested=${toBeTested}`);
  }

  // AUGER LIST BY PLANT //
  _getAugerByPlant() {
    return this.http.get(`${API_GET_AUGER_BY_PLANT}?PlantID=${this.helper.getSelectedPlant()}`);
  }

  // USER CATEGORY LIST //
  _getUserCategory() {
    return this.http.get(`${GET_ALL_USER_CATEGORY}`);
  }

  // ALL USER LIST BY CATEGORY //
  _getAllUserList() {
    return this.http.get(`${GET_ALL_USER_LIST}?UserCategory=${this.helper.getUserCategory()}`);
  }

  /* For New Version */
  /* Transporter Insert and Update */
  _getTransporterListing(queryParam?: string) {
    let url = API_GET_TRANSPORTER_LISTING;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  /* Transporter Listing */
  _saveTransporterMstData(mstData: any) {
    return this.http.post(API_POST_TRANSPORTER_INSERT, mstData);
  }


 /* Type Listing */
  _GetTypeList(ID: any) {
    return this.http.post(GET_TYPE_LIST, ID);
  }
  _INSERT_TYPE_MASTER(DATA: any) {
    return this.http.post(INSERT_TYPE_MASTER, DATA);
  }

   /* Transporter Delete */
   _getTransporterDelete(queryParam?: string) {
    let url = API_GET_TRANSPORTER_DELETE;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  _getAllPilantMines(){    
    return this.http.get(`${API_GET_ALL_PLANT_MINES}?PlantID=${this.helper.getSelectedPlant()}`);    
  }
  _getDOListing(queryParam?: string) {
    let url = API_GET_ALL_DO_LIST;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }
  _getDOStatusChange(queryParam?: string) {
    let url = API_GET_STATUS_CHANGE;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }
  _saveDOData(mstData: any) {
    return this.http.post(API_POST_DO_SAVE, mstData);
  }

  _getDoCollectionType(queryParam?: string) {
    let url = API_DO_COLLECTION_TYPES;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  _getAllMinesMst(queryParam?: string) {
    let url = API_ALL_MINES_MASTER;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  _getPlantMineMappingDelete(queryParam?: string) {
    let url = API_GET_PLANT_MINE_DELETE;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }

  /* For New Version */
  _getAllLocation(queryParam?: string) {
    let url = API_GET_LOCATION_LIST;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.get(url);
  }
  _saveLocationData(locationData: any) {
    return this.http.post(API_INSERT_UPDATE_LOCATION_DATA, locationData);
  }

  _getAllCustomer() {
    return this.http.get(API_GET_CUSTOMERS);
  }
  _getAllSamplingPoint() {
    return this.http.get(API_GET_ALL_SAMPLING_POINT);
  }
 /* Rfid Master Listing */
 _GetRfidMasterList(ID: any) {
  return this.http.post(API_GET_RFID_MASTER_LIST, ID);
}
}
