//export const BASE_URL = 'http://148.72.207.113:3536/';  // FOR INTERNAL
//export const LAB_BASE_URL = 'http://148.72.207.113:3536';  // FOR INTERNAL

//export const BASE_URL = 'http://mbplimsdemoapi.vvsindia.com/'; // FOR CLIENT
//export const LAB_BASE_URL = 'http://mbplimsdemoapitwo.vvsindia.com'; // FOR CLIENT


export const BASE_URL = 'https://bvlimsapi.vvsindia.com/';  // FOR PRODUCTION
export const LAB_BASE_URL = 'https://bvlimsapi.vvsindia.com';  // FOR PRODUCTION


//======= VVS Server build ===============
//ng build --prod
//========================================

//======= prod Server build ==============
//ng build --prod --base-href /lims/
//========================================


// MASTER / COMMON SERVICE PART //
export const API_LOGIN = `${BASE_URL}LoginAuth`;
export const API_LOGOUT = `${BASE_URL}api/LogOut/logout`;
export const API_LOGIN_AT_PLANT_CHANGE = `${BASE_URL}api/LoginAuthentication/GetLogIn`;
export const API_GET_UD = `${BASE_URL}api/Login/GetUserDtls`;
export const API_REG = `${BASE_URL}api/Login/PostRegistration`;
export const API_POST_CHANGE_PASSWORD = `${BASE_URL}api/LoginAuthentication/PostChangePassword`;
export const API_GET_PLANTS = `${BASE_URL}api/CommonData/GetAllPlant`;
export const API_GET_USER_PLANTS = `${BASE_URL}api/CommonData/GetAllPlantByUser`;
export const API_GET_COLLECTION_TYPES = `${BASE_URL}api/CommonData/GetAllCollectionType`;
export const API_GET_MINE_LIST = `${BASE_URL}api/CommonData/GetAllMine`;
export const API_GET_DO_LIST = `${BASE_URL}api/CommonData/GetAllDoByPlantMineCollType`;
export const API_GET_PLANT_MINE_LIST = `${BASE_URL}api/CommonData/GetAllPlantMine`;
export const API_GET_FEEDER_LIST = `${BASE_URL}api/CommonData/GetAllFeeder`;
export const API_GET_TABLES = `${BASE_URL}api/CommonData/GetTableName`;
export const API_GET_TABLE_SCHEMA = `${BASE_URL}api/CommonData/GetTableDtls`;
export const API_INSERT_UPDATE_MASTER_DATA = `${BASE_URL}api/MasterData/PostMasterDataInsertUpdate`;
export const API_GET_MASTER_DATA_LIST = `${BASE_URL}api/MasterData/GetMasterDataListing`;
export const API_GET_MASTER_DATA_DELETE = `${BASE_URL}api/MasterData/GetMasterDataDelete`;
export const API_GET_TRANSPORTER_LIST = `${BASE_URL}api/CommonData/GetAllTransporter`;
export const API_GET_DEPT_LIST = `${BASE_URL}api/CommonData/GetDepartment`;
export const API_GET_DESIGNATION_LIST = `${BASE_URL}api/CommonData/GetDesignation`;
export const GET_ALL_SAMPLE_PART_SIZE = `${BASE_URL}api/CommonData/GetAllSamplePartSize`;
export const GET_ALL_USER_CATEGORY = `${BASE_URL}api/CommonData/GetAllUserCategory`;
export const GET_ALL_USER_LIST = `${BASE_URL}api/CommonData/GetAllUserName`;
export const GET_ALL_MINE_BY_COLLECTION_TYPE = `${BASE_URL}api/CommonData/GetAllMineByCollType`;

export const API_GET_CUSTOMERS = `${BASE_URL}api/CommonData/GetAllCustomer`;

export const API_GET_SAMPLE_MTD_YTD = `${BASE_URL}api/CommonData/GetSampleCollectedMTDYTD`;

export const API_GET_ALL_PLANT_MINE_LIST = `${BASE_URL}api/CommonData/GetAllMineMst`;

export const API_GET_ALL_SAMPLE_FOR_FEEDER = `${BASE_URL}api/CommonData/GetAllSamplePartSizeForFeeder`;
export const API_GET_ALL_SHIFT = `${BASE_URL}api/CommonData/GetAllShift`;

export const API_POST_TRANSPORTER_INSERT = `${BASE_URL}api/MasterData/PostTransMasterInsertUpdate`;
export const API_GET_TRANSPORTER_LISTING = `${BASE_URL}api/MasterData/GetTransMasterListing`;
export const API_GET_TRANSPORTER_DELETE = `${BASE_URL}api/MasterData/GetTransMasterDelete`;

export const API_GET_ALL_PLANT_MINES = `${BASE_URL}api/CommonData/GetAllPlantMine`;
export const API_GET_ALL_DO_LIST = `${BASE_URL}api/ManageDO/GetDONumberListing`;
export const API_GET_STATUS_CHANGE = `${BASE_URL}api/ManageDO/DOStatusUpdate`;
export const API_POST_DO_SAVE = `${BASE_URL}api/ManageDO/PostDo`;

export const API_GET_PLANT_MINE_DELETE = `${BASE_URL}api/MasterData/GetPlantMineMasterDelete`;

// USER MANAGEMENT //
export const GET_MENU_PANEL = `${BASE_URL}api/CommonData/GetMenuPanel`;
export const GET_USER_MASTER_INSERT_UPDATE = `${BASE_URL}api/UserManagement/GetUserMasterInsertUpdate`;
export const GET_USER_MASTER_LISTING = `${BASE_URL}api/UserManagement/GetUserMasterListing`;
export const GET_USER_STATUS_CHANGE = `${BASE_URL}api/UserManagement/GetUserStatusChange`;
export const GET_USER_RESET_PASSWORD = `${BASE_URL}api/UserManagement/GetUserResetPassword`;
export const GET_USER_ACL = `${BASE_URL}api/UserManagement/GetUserACL`;
export const USER_ACL_INSERT_UPDATE = `${BASE_URL}api/UserManagement/UserACLInsertUpdate`;
export const GET_MENU_TEMPLATE_LIST = `${BASE_URL}api/UserManagement/GetMenuTemplateDefaultList`;
export const GET_ACL_LISTING = `${BASE_URL}api/AccessControl/GetACLListing`;
export const GET_ACL_BY_MENU_TEMPID = `${BASE_URL}api/AccessControl/GetACLByMenuTempID`;
export const MENU_TEMPLATE_STATUS_CHANGE = `${BASE_URL}api/AccessControl/MenuTemplateStatusChange`;
export const GET_MENU_LISTING = `${BASE_URL}api/AccessControl/GetMenuListing`;
export const MENU_TEMPLATE_INSERT_UPDATE = `${BASE_URL}api/AccessControl/MenuTemplateInsertUpdate`;
export const TEMPLATE_LIST = `${BASE_URL}api/CommonData/GetMenuTemplate`;

// SAMPLE COLLECTION PART //
export const API_GET_BIN_BAG_NO = `${BASE_URL}api/SampleCollectionTruck/GetBinBagNumber`;
export const API_GET_AUGER_BY_PLANT = `${BASE_URL}api/CommonData/GetAllAuger`;
export const API_GET_BIN_BAG_MOVEMENT_LIST = `${BASE_URL}api/BinBagMovement/GetBinBagMovementListing`;
export const API_SENT_RECEIVED_BIN_BAG = `${BASE_URL}api/BinBagMovement/PostBinBagMovementAction`;
//export const API_GET_SAMPLE_COLLECTION_TRUCK_STATUS_INSERT = `${BASE_URL}api/SampleCollectionTruck/GetSampleCollectionTruckStatusInsert`;
export const API_GET_SAMPLE_COLLECTION_TRUCK_STATUS_INSERT = `${BASE_URL}api/SampleCollectionTruck/GetSampleCollectionTruckStatusInserted`;
export const API_GET_BIN_REASSING = `${BASE_URL}api/SampleCollectionTruck/GetBinReAssign`;
export const API_COLLECTION_RAIL_BPC_FEEDER = `${BASE_URL}api/SampleCollectionRailBPCFeeder/PostSampleCollectionFromRailBPCFeeder`;
export const GET_PREPARED_SAMPLE_LISTING = `${BASE_URL}api/SamplePreparation/GetPreparedSampleListing`;
export const GET_RAIL_BPC_FEEDER_LISTING = `${BASE_URL}api/SampleCollectionRailBPCFeeder/GetSampleFromRailBPCFeederListing`;
export const SET_BACK_OUT_OFF_SERVICE_BIN = `${BASE_URL}api/BinBagMovement/PostSetBackOutOffServiceBin`;
export const GET_OUT_ORDER_BINS_LISTING = `${BASE_URL}api/BinBagMovement/GetOutOffServiceBinList`;
export const API_BIN_SET_BACK = `${BASE_URL}api/BinBagMovement/GetSetBackOutOffServiceBin`;
export const API_GET_BIN_ASSIGN_SHORTAGE_ON_BIN_SEND = `${BASE_URL}api/BinBagMovement/GetBinAssignShortageOnBinSend`;

//For New Version //
export const API_SAMPLE_COLLECTION_TRUCK_SAVE = `${BASE_URL}api/SampleCollectionTruckNew/PostSampleCollectionTruckNew`;
export const API_SAMPLE_COLLECTION_TRUCK_LIST = `${BASE_URL}api/SampleCollectionTruckNew/GetSampleCollectionTruckNewListing`;
export const API_SAMPLE_COLLECTION_RAIL_RAKE_SAVE = `${BASE_URL}api/SampleCollectionRailNew/PostSampleCollectionRailNew`;
export const API_GET_FINYAER_CODE = `${BASE_URL}api/SampleCollectionRailNew/GetFinYearCode`;
export const API_SAMPLE_COLLECTION_RAIL_RAKE_LIST = `${BASE_URL}api/SampleCollectionRailNew/GetSampleCollectionRailNewListing`;
export const API_SAMPLE_COLLECTION_RAIL_RAKE_DETAILS = `${BASE_URL}api/SampleCollectionRailNew/GetSampleCollectionDtlsRailNew`;
export const API_SAMPLE_COLLECTION_RAIL_RAKE_SUBLOTS = `${BASE_URL}api/SampleCollectionRailNew/GetSampleCollectionSubLotDtlsRailNew`;
export const API_GET_BIN_BAG_NOS = `${BASE_URL}api/SampleCollectionTruck/GetBinBagNumbers`;
export const API_RAIL_RAKE_SUBLOT_DETAILS = `${BASE_URL}api/SampleCollectionRailNew/GetRailRakeSubLotDtls`;
export const API_RAIL_RAKE_ADDTNL_INFO = `${BASE_URL}api/RakeAdditionalInfo/GetVTSRakeAdditionalInfoListing`;
export const API_TYPE = `${BASE_URL}api/RakeAdditionalInfo/GetAllType`;
export const API_LOCATION = `${BASE_URL}api/RakeAdditionalInfo/GetAllLocation`;
export const API_InsertVTSRakeAdditionalInfo = `${BASE_URL}api/RakeAdditionalInfo/InsertVTSRakeAdditionalInfo`;
export const API_InsertVTSRakeAdditionalInfoFile =  `${BASE_URL}api/RakeAdditionalInfo/UploadVTSRakeAdditionalInfoFile`;

export const API_ALL_MINES_MASTER = `${BASE_URL}api/CommonData/GetAllMineMst`;
export const API_DO_COLLECTION_TYPES = `${BASE_URL}api/CommonData/GetAllCollectionTypeForDO`;
export const API_TRUCK_ADDTNL_INFO = `${BASE_URL}api/TruckAdditionalInfo/GetVTSTruckAdditionalInfoListing`;
export const API_TRUCK_TYPE = `${BASE_URL}api/TruckAdditionalInfo/GetAllType`;
export const API_TRUCK_LOCATION = `${BASE_URL}api/TruckAdditionalInfo/GetAllLocation`;
export const API_InsertVTSTruckAdditionalInfo = `${BASE_URL}api/TruckAdditionalInfo/InsertVTSTruckAdditionalInfo`;
//For New Version //

// SAMPLE COLLECTION PART //


// SAMPLE PREPARATION PART //
export const SAMPLE_PREPARATION_LIST_IN_MM = `${BASE_URL}api/SamplePreparation/GetCollectionReceiveForSamplePreparationListing`;
export const RECEIVE_AND_PREPARATION_IN_MM = `${BASE_URL}api/SamplePreparation/GetCollectionReceiveForSamplePreparationAction`;
export const API_GET_FINAL_PREPARATION_LIST = `${BASE_URL}api/SamplePreparation/GetSampleReceiveForFinalPreparationListing`;
export const API_GET_FINAL_PREPARATION_ACTION = `${BASE_URL}api/SamplePreparation/GetSampleReceiveForFinalPreparationAction`;

//For Resampling Part //
export const SAMPLE_DETAILS = `${BASE_URL}api/ReSampling/GetSampleDtlsForResampling`;
export const RESAMPLE_ENTRY = `${BASE_URL}api/ReSampling/ReSamplingEntry`;
export const RESAMPLING_LIST = `${BASE_URL}api/ReSampling/GetSampleDtlsForResamplingListing`;
export const RESAMPLING_REPRINT_LIST = `${BASE_URL}api/SamplePreparation/GetPreparedSampleListingForReprint`;
//For Resampling Part //


//typemaster//
export const GET_TYPE_LIST = `${BASE_URL}api/TypeMaster/GetTypeList`;
export const INSERT_TYPE_MASTER = `${BASE_URL}api/TypeMaster/InsertUpdateTypeMaster`;
// SAMPLE PREPARATION PART //
export const GET_VIEW_SAMPLE_SOURCE = `${BASE_URL}api/SourceInfo/GetSourceInfoBySampleRefNo`;
export const GET_SAMPLE_SIZE = `${BASE_URL}api/CommonData/GetAllSamplePartSizeSourceInfo`;
export const GET_COLLECTION_TYPE = `${BASE_URL}api/CommonData/GetAllCollectionType`;
export const GET_SEARCH_DETAILS = `${BASE_URL}api/SourceInfo/GetSourceInfoBySampleDateOrCollectionDate`
// LAB PART //
export const GET_FRONT_OFC_REG_URL = LAB_BASE_URL + '/api/FrontOffice/GetFrontOfficeRegister';
export const GET_LAB_CODE_URL = LAB_BASE_URL + '/api/FrontOffice/GetLabCode';
export const INSERT_REGISTER_DATA_URL = LAB_BASE_URL + '/api/FrontOffice/InsertFrontOfficeRegister';
export const GET_SAMPLE_REGISTER_URL = LAB_BASE_URL + '/api/LabSampleRegister/GetSampleRegister';
export const GET_PENDING_SAMPLE_URL = LAB_BASE_URL + '/api/LabTestInitiation/GetPendingSample';
export const GET_ANALYSIS_INITIATED_URL = LAB_BASE_URL + '/api/LabTestInitiation/GetAnalysisInitiated';
export const GET_PENDING_JOB_ORDER_LIST_URL = LAB_BASE_URL + '/api/LabJobOrderlist/GetPendingJobOrderlist';
export const GET_PENDING_JOB_ORDER_DTLS_URL = LAB_BASE_URL + '/api/LabJobOrderlist/GetPendingJobOrderDtls';
export const CHANGE_INITIATION_STATUS_URL = LAB_BASE_URL + '/api/LabTestInitiation/ChangeInitiationStatus';
export const CREATE_JOB_ORDER_URL = LAB_BASE_URL + '/api/LabTestInitiation/CreateJobOrder';
export const CHANGE_INITIATION_BY_BARCODE_URL = LAB_BASE_URL + '/api/LabTestInitiation/ChangeInitiationByBarcode';
export const GET_RESULT_FOR_TM_URL = LAB_BASE_URL + '/api/LabTestResultEntry/GetResultForTM';
export const SAVE_TM_RESULT_URL = LAB_BASE_URL + '/api/LabTestResultEntry/InsertTMResult';
export const GET_RESULT_FOR_QUALITY_URL = LAB_BASE_URL + '/api/LabTestResultEntry/GetResultForQuality';
export const SAVE_GCV_RESULT_URL = LAB_BASE_URL + '/api/LabTestResultEntry/InsertGCVResult';
export const SAVE_IMVM_RESULT_URL = LAB_BASE_URL + '/api/LabTestResultEntry/InsertIMVMResult';
export const SAVE_EM_RESULT_URL = LAB_BASE_URL + '/api/LabTestResultEntry/InsertEMResult';
export const GET_APPRVL_JO_LST_URL = LAB_BASE_URL + '/api/LabResultApproval/GetResultApprovalJOlist';
export const GET_RES_TM_APPRVL_URL = LAB_BASE_URL + '/api/LabResultApproval/GetResultForTMApproval';
export const APPROVE_TM_RES_URL = LAB_BASE_URL + '/api/LabResultApproval/ApproveTMResult';
export const GET_RES_QUALITY_APPRVL_URL = LAB_BASE_URL + '/api/LabResultApproval/GetResultForQualityApproval';
export const APPROVE_GCV_RES_URL = LAB_BASE_URL + '/api/LabResultApproval/ApproveGCVResult';
export const APPROVE_EM_RES_URL = LAB_BASE_URL + '/api/LabResultApproval/ApproveEMResult';
export const APPROVE_IMVM_RES_URL = LAB_BASE_URL + '/api/LabResultApproval/ApproveIMVMResult';
export const GET_PLANTS_BY_USER_URL = BASE_URL + 'api/CommonData/GetAllPlantByUser';
export const GET_MINES_URL = BASE_URL + 'api/CommonData/GetAllMine';
export const GET_FEEDERS_URL = BASE_URL + 'api/CommonData/GetAllFeeder';
export const GET_BPC_REPORT_DTL_URL = LAB_BASE_URL + '/api/LabTestReport/GetBPCReportDtls';
export const GET_FEEDER_REPORT_DTL_URL = LAB_BASE_URL + '/api/LabTestReport/GetFeederReportDtls';
export const GET_RAKE_REPORT_DTL_URL = LAB_BASE_URL + '/api/LabTestReport/GetRakeReportDtls';
export const GET_TRUCK_REPORT_DTL_URL = LAB_BASE_URL + '/api/LabTestReport/GetTruckReportDtls';
export const GET_FEEDER_REPORT_DTL_BEFORE_APPROVE_URL = LAB_BASE_URL + '/api/LabTestReport/GetFeederReportDtlsBeforeApproval';
export const GET_RAKE_REPORT_DTL_BEFORE_APPROVE_URL = LAB_BASE_URL + '/api/LabTestReport/GetRakeReportDtlsBeforeApproval';
export const GET_TRUCK_REPORT_DTL_BEFORE_APPROVE_URL = LAB_BASE_URL + '/api/LabTestReport/GetTruckReportDtlsBeforeApproval'

export const GET_SAMPLE_DTLS_FOR_CORRECTION = LAB_BASE_URL + '/api/LabTestResultEntryCorrection/GetSampleDtlsForCorrection';
export const GET_RESULT_FOR_TM_CORRECTION = LAB_BASE_URL + '/api/LabTestResultEntryCorrection/GetResultForTMCorrection';
export const GET_RESULT_FOR_QUALITY_CORRECTION = LAB_BASE_URL + '/api/LabTestResultEntryCorrection/GetResultForQualityCorrection';
// PAGE_CONFIG //
export const PAGE_CONFIG = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0
};
export const TOASTER_TIMEOUT = {
    SUCCESS: { timeOut: 7000 },
    ERROR: { timeOut: 7000 }
};
export const NOT_FOUND = "No Records Found...";
export const DAY_DIFF: number = 92;



// LOCATION PART //
export const API_GET_LOCATION_LIST = BASE_URL + 'api/MasterData/GetLocationMasterDataListing';
export const API_INSERT_UPDATE_LOCATION_DATA = BASE_URL + 'api/MasterData/LocationMasterDataInsertUpdate';
//RFID MASTER//
export const API_GET_RFID_MASTER_LIST = `${BASE_URL}api/MasterData/GetRFIDMasterList`;
//RFID MASTER//
export const API_RFID_MASTER_INSERT_UPDATE = `${BASE_URL}api/RFIDNo/RFIDMasterInsertUpdate`;
export const API_GET_ALL_SAMPLING_POINT = `${BASE_URL}api/CommonData/GetAllSamplingPoint`;



