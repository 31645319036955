import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  message: string;
  action: string;
}

@Component({
  selector: 'app-sample-collection-alert',
  templateUrl: './sample-collection-alert.component.html',
  styleUrls: ['./sample-collection-alert.component.scss']
})
export class SampleCollectionAlertComponent implements OnInit {
  public jsonData: DialogData;
  constructor(
    public dialogRef: MatDialogRef<SampleCollectionAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { 
      this.jsonData = data;
      }

  onNoClick(action: boolean): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }
}
