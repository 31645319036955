import { DatePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';

/**
 * Use : Helper functions
 * Descriprion : Default Helper Class
 * Author : VVS
 */

export class Helper {

  constructor() { }

  dateFormat(dateValue = '', format = 'dd/MM/yyyy') {
    if (dateValue === '') { return dateValue; }
    const datePipe = new DatePipe('en-US');
    dateValue = datePipe.transform(dateValue, format);
    return dateValue;
  }

  removeNullElement(formData: any) {
    const filteredData = {};
    for (const [key, value] of Object.entries(formData)) {
      if (value == null || value === '') { continue; }
      filteredData[key] = value;
    }
    return filteredData;
  }

  isEmptyObject(obj = {}) {
    return !Object.keys(obj).length;
  }

  isObjectEmpty(Obj) {
    for (const key in Obj) {
      if (Obj.hasOwnProperty(key)) { return false; }
    }
    return true;
  }

  scrollTop(content = null) {
    if (content != null) { content.scrollToTop(1500); }
  }

  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('limsUser')).access_token
      })
    };
    // console.log( 'Bearer ' + JSON.parse(sessionStorage.getItem('limsUser')).access_token)
    return httpOptions;
  }

  loginHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    return httpOptions;
  }

  ucFirst(inputString: string) {
    // tslint:disable-next-line: max-line-length
    return (typeof inputString === 'string' && inputString.length > 0) ? inputString.charAt(0).toUpperCase() + inputString.substr(1).toLowerCase() : inputString;
  }

  getUserDetails() {
    return JSON.parse(sessionStorage.getItem('AdmUserDetails'));
  }

  arraytoString(arr: any) {
    return arr.toString();
  }

  getAuthUser() {
    return JSON.parse(sessionStorage.getItem('limsUser'));
  }

  getFormDate(dateValue: any) {
    return this.dateFormat(dateValue, 'yyyy-MM-dd');
  }

  getSelectedPlant() {
    return JSON.parse(sessionStorage.getItem('selectedPlant'));
  }

  getUserCategory() {
    return JSON.parse(sessionStorage.getItem('userCategory'));
  }

  getSelectedPlantCode() {
    return sessionStorage.getItem('selectedPlantCode');
  }

  selectList(jobOrderNo: string, prevjobOrdNo: string): string {
    if (prevjobOrdNo != undefined) {
      let myDiv = document.getElementById(prevjobOrdNo);
      if (myDiv != null) {
        myDiv.style.backgroundColor = 'white'; // updating the div via its properties
      }
    }
    let myDiv = document.getElementById(jobOrderNo);
    myDiv.style.backgroundColor = 'orange'; // updating the div via its properties
    return jobOrderNo;
  }

  allowTwoDecimal(event: any) {
    if (event.charCode == 32) event.preventDefault();
    // const pattern = /[0-9\+\-\ ]/;
    const pattern = /^\d*\.?\d{0,2}$/g;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
    let numval = event.target.value + "" + event.key;
    if (numval.indexOf('.') !== -1) {
      let decivalSplit = numval.split('.');
      if (decivalSplit[1].length > 2) {
        event.preventDefault();
      }
    }
  }

  allowInt(event: any) {
    if (event.charCode == 32) event.preventDefault();
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  calculateDiff(fromDate: any, dateSent: any) {
    let currentDate = new Date(fromDate);
    dateSent = new Date(dateSent);
    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
  }

}
