<h1 class="text-cyan modal-heading">Confirmation</h1>
<div class="modal-body height-pop-body">
  <p>{{ data.message }}</p>
  <div class="table-responsive" *ngIf="tableData">
    <table class="table card-table table-vcenter table-bordered text-center">
      <thead class="bg-darkblue text-white">
        <tr>
          <th class="text-white">Vehicle Registration No</th>
          <th class="text-white">Vehicle Entry Date</th>
          <th class="text-white">Primary Collection No.</th>
          <th class="text-white">Scan Count</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let row of tableData
              | paginate
                : {
                    id: 'server',
                    itemsPerPage: pageSize,
                    currentPage: currentPage,
                    totalItems: totalRecord
                  }
          "
        >
          <td>{{ row.VehRegNo }}</td>
          <td>{{ row.VehEntryDate }}</td>
          <td>{{ row.CSCTSVTSRefNo }}</td>
          <td>{{ row.Count }}</td>
        </tr>
        <tr *ngIf="totalRecord == 0">
          <td colspan="4" class="no-data-available text-bold text-danger">
            No Records found!
          </td>
        </tr>
      </tbody>
    </table>
    <pagination-controls
      (pageChange)="getBinAssignShortageOnBinSend($event)"
      id="server"
    ></pagination-controls>
  </div>
</div>

<div class="modal-footer text-right">
  <button class="btn btn-sm btn-gray" (click)="onNoClick(false)">Cancel</button>

  <button class="btn btn-sm btn-cyan" [mat-dialog-close]="true">Yes</button>
</div>
