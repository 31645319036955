import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Route, CanLoad } from '@angular/router';
import { AuthenticationService } from '../_services';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanLoad, CanActivate {
    isLoggedIn: Observable<boolean>;

    constructor(
        private router: Router,
        private authService: AuthenticationService) {
        this.isLoggedIn = this.authService.isLoggedIn();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (sessionStorage.getItem('limsUser')) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['admin/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }


    canLoad(route: Route): boolean {
        if (sessionStorage.getItem('limsUser')) {
            // logged in so return true
            return true;
        }
        this.router.navigate(['admin/login']);
        return false;
    }
}



