

<div class="container">
  <h1>Delete {{data?.message}}</h1>
  <p>Are you sure you want to delete {{data?.message}} data?</p>
  
  <div class="clearfix">
    <button class="cancelbtn" mat-button (click)="onNoClick(false)">Cancel</button>

    <button class="deletebtn" mat-button [mat-dialog-close]="true">Delete</button>
  </div>
</div>