import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  API_COLLECTION_RAIL_BPC_FEEDER,
  GET_RAIL_BPC_FEEDER_LISTING,
  API_GET_BIN_BAG_NO,
  API_GET_AUGER_BY_PLANT, API_GET_BIN_BAG_MOVEMENT_LIST,
  API_SENT_RECEIVED_BIN_BAG,
  API_GET_SAMPLE_COLLECTION_TRUCK_STATUS_INSERT,
  SET_BACK_OUT_OFF_SERVICE_BIN,
  API_GET_BIN_REASSING,
  GET_OUT_ORDER_BINS_LISTING,
  API_BIN_SET_BACK,
  API_SAMPLE_COLLECTION_TRUCK_LIST,
  API_SAMPLE_COLLECTION_TRUCK_SAVE,
  API_SAMPLE_COLLECTION_RAIL_RAKE_SAVE,
  API_SAMPLE_COLLECTION_RAIL_RAKE_LIST,
  API_SAMPLE_COLLECTION_RAIL_RAKE_DETAILS,
  API_SAMPLE_COLLECTION_RAIL_RAKE_SUBLOTS,
  API_GET_BIN_BAG_NOS,
  API_RAIL_RAKE_SUBLOT_DETAILS,
  API_GET_ALL_SAMPLE_FOR_FEEDER,
  API_GET_ALL_SHIFT,
  API_RAIL_RAKE_ADDTNL_INFO,
  API_TYPE,
  API_LOCATION,
  API_InsertVTSRakeAdditionalInfo,
  API_TRUCK_ADDTNL_INFO,
  API_TRUCK_TYPE,
  API_TRUCK_LOCATION,
  API_InsertVTSTruckAdditionalInfo,
  API_GET_FINYAER_CODE,
  API_InsertVTSRakeAdditionalInfoFile,
  API_GET_BIN_ASSIGN_SHORTAGE_ON_BIN_SEND
} from '../../_common/constant';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class SampleCollectionService {

  constructor(private http: HttpClient) { }

  // GET AUGER LIST //
  _augerList(queryParam: string) {
    return this.http.get(API_GET_AUGER_BY_PLANT + queryParam);
  }

  // GET BIN OR BAG NUMBER //
  _getBinBagNo(queryParam: string) {
    return this.http.get(API_GET_BIN_BAG_NO + queryParam);
  }

  // GET BIN OR BAG NUMBER //
  /*_getBinBagNumber(vehicleRegNo: string) {
    return this.http.get(`${API_GET_BIN_BAG_NO}?AugerID=${augerID}&AugerCode=${augerCode}&VehicleRegNo=${vehicleRegNo}`).pipe(
      catchError(() => throwError('Error Occurs'))
    );
  }*/

  _getBinBagNumber(vehicleRegNo: string) {
    return this.http.get(`${API_GET_BIN_BAG_NOS}?CSCTSVTSRefNo=${vehicleRegNo}`).pipe(
      catchError(() => throwError('Error Occurs'))
    );
  }

  // COLLECT SAMPLE OR SKIP //
  _getSampleCollectionInsert(queryParam: any) {
    return this.http.post(API_GET_SAMPLE_COLLECTION_TRUCK_STATUS_INSERT , queryParam);
  }

  // BIN RE-ASSING WITH NEW ID //
  _getbinReAssign(queryParam: string) {
    return this.http.get(API_GET_BIN_REASSING + queryParam);
  }

  // ALL BIN/BAG MOVEMENT LIST //
  _getBinBagMovementList(queryParam: string) {
    return this.http.get(API_GET_BIN_BAG_MOVEMENT_LIST + queryParam);
  }

  // THIS FUNCTION IS USED TO SENT AND RECEIVED BIN/BAG DATA //
  _postBinBagSentRecieved(binbagData: any) {
    return this.http.post(API_SENT_RECEIVED_BIN_BAG, binbagData);
  }

  // RAIL RAKE, BPC, FEEDER & ROAD COLLECTION //
  _collectionRailBpcFeeder(rrbfData: any) {
    return this.http.post(API_COLLECTION_RAIL_BPC_FEEDER, rrbfData);
  }

  // GET RAIL BPC FEEDER LISTING //
  railBpcFeederRoadListing(
    plantID: number,
    colTypeID: number,
    mineFeeder: number,
    mineFeederID: number,
    railRakeNo: string,
    transID: number,
    fromDate: string,
    toDate: string,
    currentPage: number,
    pageSize: number) {
    const QRY_PARAMS = `?PlantID=${plantID}&CollectionTypeID=${colTypeID}&MineFeeder=${mineFeeder}&MineFeederID=${mineFeederID}&RailRakeNo=${railRakeNo}&TransID=${transID}&FromDate=${fromDate}&ToDate=${toDate}&CurrentPage=${currentPage}&PageSize=${pageSize}`
    return this.http.get(`${GET_RAIL_BPC_FEEDER_LISTING}${QRY_PARAMS}`).pipe(
      catchError(() => throwError('Error occurs'))
    );
  }

  // SET BACK OUT OFF SERVICE BIN //
  postSetBackOutOffServiceBin(oops: any) {
    return this.http.post(SET_BACK_OUT_OFF_SERVICE_BIN, oops).pipe(catchError(() => throwError('Error Occurs')));
  }

  // ALL BIN OUT OF ORDER LIST //
  _getBinOutofOrderList(queryParam: string) {
    return this.http.get(GET_OUT_ORDER_BINS_LISTING + queryParam);
  }

  // BIN SET BACK //
  _getBinSetBack(queryParam: string) {
    return this.http.get(API_BIN_SET_BACK + queryParam);
  }

  //For New Version //
  _getSampleCollectionTruckList(queryParam: string) {
    return this.http.get(API_SAMPLE_COLLECTION_TRUCK_LIST + queryParam);
  }
  _postSampleCollectionTruckSave(rrbfData: any) {
    return this.http.post(API_SAMPLE_COLLECTION_TRUCK_SAVE, rrbfData);
  }
  _postSampleCollectionRailRakeSave(rrbfData: any) {
    return this.http.post(API_SAMPLE_COLLECTION_RAIL_RAKE_SAVE, rrbfData);
  }
  _getFinYearCode(queryParam: string) {
    return this.http.get(API_GET_FINYAER_CODE + queryParam);
  }
  
  _getSampleCollectionRailRakeList(queryParam: string) {
    return this.http.get(API_SAMPLE_COLLECTION_RAIL_RAKE_LIST + queryParam);
  }
  _getSampleCollectionRailRakeDetails(queryParam: string) {
    return this.http.get(API_SAMPLE_COLLECTION_RAIL_RAKE_DETAILS + queryParam);
  }
  _getSampleCollectionRailRakeSublots(queryParam: string) {
    return this.http.get(API_SAMPLE_COLLECTION_RAIL_RAKE_SUBLOTS + queryParam);
  }
  _getRailRakeSublotDetails(queryParam: string) {
    return this.http.get(API_RAIL_RAKE_SUBLOT_DETAILS + queryParam);
  }

  _getAllSamplePartSizeForFeeder() {
    return this.http.get(API_GET_ALL_SAMPLE_FOR_FEEDER);
  }

  _getAllShift(queryParam: string) {
    return this.http.get(API_GET_ALL_SHIFT + queryParam);
  }

  _GetVTSRakeAdditionalInfoListing(queryParam: string) {
    return this.http.get(API_RAIL_RAKE_ADDTNL_INFO + queryParam);
  }

  _GetAllType() {
    return this.http.get(API_TYPE);
  }

  _GetAllLocation() {
    return this.http.get(API_LOCATION);
  }

  _postInsertVTSRakeAdditionalInfo(Data: any) {
    return this.http.post(API_InsertVTSRakeAdditionalInfo, Data);
  }
  _postInsertVTSRakeAdditionalInfoFile(Data: any) {
    return this.http.post(API_InsertVTSRakeAdditionalInfoFile, Data);
  }

// FOR TRUCK

_GetVTSTruckAdditionalInfoListing(queryParam: string) {
  return this.http.get(API_TRUCK_ADDTNL_INFO + queryParam);
}

_GetAllTypeTruck() {
  return this.http.get(API_TRUCK_TYPE);
}

_GetAllLocationTruck() {
  return this.http.get(API_TRUCK_LOCATION);
}

_postInsertVTSTruckAdditionalInfo(Data: any) {
  return this.http.post(API_InsertVTSTruckAdditionalInfo, Data);
}

  //For New Version //
_getBinAssignShortageOnBinSend(qString:any){
  return this.http.get(API_GET_BIN_ASSIGN_SHORTAGE_ON_BIN_SEND+qString)
}
}
