import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SampleCollectionService } from '../../_services/scollection/sample-collection.service';

export interface DialogData {
  message: string;
}
@Component({
  selector: 'app-confirm-alert',
  templateUrl: './confirm-alert.component.html',
  styleUrls: ['./confirm-alert.component.scss']
})
export class ConfirmAlertComponent implements OnInit {
  currentPage = 1;
  pageSize = 10;
  totalRecord: number;
  tableData: any;
  binbagData: any;
  constructor(
    public dialogRef: MatDialogRef<ConfirmAlertComponent>,
    private scolService: SampleCollectionService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(action: boolean): void {
    this.dialogRef.close();
  }
  getBinAssignShortageOnBinSend(pageNo: any) {
    let qString =
      "?PlantID=" +
      this.binbagData.PlantID +
      "&AugerID=" +
      this.binbagData.AugerID +
      "&BinBagNo=" +
      this.binbagData.BinBagNo +
      "&CurrentPage=" +
      pageNo +
      "&PageSize=10";
    this.scolService
      ._getBinAssignShortageOnBinSend(qString)
      .subscribe((res: any) => {
        this.tableData = res.Data;
        this.currentPage = pageNo;
      });
  }
  ngOnInit(): void {
    this.totalRecord = this.data.tableData.TotalRecords;
    this.tableData = this.data.tableData.Data;
    this.binbagData = this.data.binbag;
  }
}
