import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models';
import {
  API_LOGIN,
  API_LOGIN_AT_PLANT_CHANGE,
  API_GET_PLANTS, API_GET_USER_PLANTS,
  API_POST_CHANGE_PASSWORD,API_LOGOUT
} from '../_common/constant';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('limsUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // CHECK USER ALLREADY LOGGED IN OR NOT AND REDIRECT //
  checkIsLogin() {
    if (!sessionStorage.getItem('limsUser')) {
      this.router.navigate(['admin/login']);
    } else {
      this.router.navigate(['admin/app/dashboard'])
    }
  }

  // GET CURRENT USER //
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  // IF WE HAVE TOKEN THE USER IS LOGGEDIN //
  private hasToken(): boolean {
    return !!sessionStorage.getItem('limsUser');
  }

  // CHECK LOGIN OR NOT //
  isLoggedIn(): Observable<boolean> {
    return this.isLoginSubject.asObservable();
  }

  // GET ALL PANT LIST //
  _getPlantList() {
    return this.http.get(API_GET_PLANTS);
  }

  // GET PANT LIST BY USER //
  _getPlantListByUser() {
    return this.http.get(API_GET_USER_PLANTS);
  }

  // USER LOGIN //
  login(loginData: any,DeviceInfo : any) {
    const params = `grant_type=${loginData.grant_type}&UserName=${loginData.UserName}&Password=${loginData.Password}&Scope=${loginData.Scope}&BrowserName=${DeviceInfo.BrowserName}&browserVersion=${DeviceInfo.browserVersion}&platform=${DeviceInfo.platform}`;
    return this.http.post<any>(API_LOGIN, params).pipe(map((user: User) => {
      if (user && user.access_token) {
        console.log(user);
        this.isLoginSubject.next(true);
        this.currentUserSubject.next(user);
        sessionStorage.setItem('limsUser', JSON.stringify(user));
        sessionStorage.setItem('selectedPlant', user.PlantID);
        sessionStorage.setItem('selectedPlantCode', user.PlantCode);
        sessionStorage.setItem('userCategory', user.UserCategory);
        return user;
      }
    }));
  }

  // RELOGIN AT PLANT CHANGE //
  loginAtPlantChange(plantId: number) {
    
    return this.http.get<any>(`${API_LOGIN_AT_PLANT_CHANGE}?PlantID=${plantId}`).pipe(map((user: User) => {
      if (user && user.access_token) {
        this.isLoginSubject.next(true);
        this.currentUserSubject.next(user);
        localStorage.clear();
        sessionStorage.clear();
        sessionStorage.setItem('limsUser', JSON.stringify(user));
        sessionStorage.setItem('selectedPlant', user.PlantID);
        sessionStorage.setItem('selectedPlantCode', user.PlantCode);
        sessionStorage.setItem('userCategory', user.UserCategory);
        return user;
      }
    }));
  }

  // PASSWORD CHANGE //
  _resetPassword(jsonData: any) {
    return this.http.post(API_POST_CHANGE_PASSWORD, jsonData)
  }
  _logout(jsonData: any) {
    return this.http.post(API_LOGOUT,jsonData)
  }
  // LOG OUT //
  // _logout() {
  //   // remove user from local storage to log user out


  //   sessionStorage.removeItem('limsUser');
  //   sessionStorage.clear();
  //   localStorage.clear();
  //   this.currentUserSubject.next(null);
  //   this.isLoginSubject.next(false);
  //   this.router.navigate(['admin/login']);
  // }

}
