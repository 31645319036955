import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Helper } from './../../_helper/default';
import {
  GET_MENU_PANEL,
  GET_USER_MASTER_INSERT_UPDATE,
  GET_USER_MASTER_LISTING,
  GET_USER_STATUS_CHANGE,
  GET_USER_RESET_PASSWORD,
  GET_USER_ACL,
  USER_ACL_INSERT_UPDATE,
  GET_MENU_TEMPLATE_LIST,
  GET_ACL_LISTING,
  GET_ACL_BY_MENU_TEMPID,
  MENU_TEMPLATE_STATUS_CHANGE,
  GET_MENU_LISTING,
  MENU_TEMPLATE_INSERT_UPDATE,
  TEMPLATE_LIST
} from '../../_common/constant';
@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(
    private helper: Helper,
    private http: HttpClient) { }

  // MENU PANEL BY PLANT & USER //
  getMenuPanel() {
    return this.http.get(`${GET_MENU_PANEL}?PlantID=${this.helper.getSelectedPlant()}`);
  }

  // USER MASTER INSERT/UPDATE //
  userMasterInsertUpdate(umstData: any) {
    let userID = umstData.UserID;
    let userCategory = umstData.UserCategory;
    let empCode = umstData.EmpCode;
    let userName = umstData.UserName;
    let name = umstData.Name;
    let emailID = umstData.EmailID;
    let mobileNo = umstData.MobileNo;
    const PARAMS = `UserID=${userID}&UserCategory=${userCategory}&EmpCode=${empCode}&UserName=${userName}&Name=${name}&EmailID=${emailID}&MobileNo=${mobileNo}`;
    return this.http.get(`${GET_USER_MASTER_INSERT_UPDATE}?${PARAMS}`);
  }

  // USER MASTER LISTING //
  getUserMasterListing(umstData: any, currPage: number, pageSize: number) {
    let userCategory = umstData.UserCategory ? umstData.UserCategory : 0;
    let empCode = umstData.EmpCode;
    let userName = umstData.UserName;
    let name = umstData.Name;
    let emailID = umstData.EmailID;
    let mobileNo = umstData.MobileNo;
    const PARAMS = `UserCategory=${userCategory}&EmpCode=${empCode}&UserName=${userName}&Name=${name}&EmailID=${emailID}&MobileNo=${mobileNo}&CurrentPage=${currPage}&PageSize=${pageSize}`;
    return this.http.get(`${GET_USER_MASTER_LISTING}?${PARAMS}`);
  }

  // USER STATUS CHANGE //
  userStatusChange(userID: number) {
    return this.http.get(`${GET_USER_STATUS_CHANGE}?UserID=${userID}`);
  }
  
 // TEMPLATE LIST //
 templateList() {
  return this.http.get(`${TEMPLATE_LIST}`);
}
  // RESET USER PASSWORD //
  resetUserPassword(userID: number) {
    return this.http.get(`${GET_USER_RESET_PASSWORD}?UserID=${userID}`);
  }

  // GET USER ACL //
  getUserACL(userID: number, plantID: number) {
    return this.http.get(`${GET_USER_ACL}?UserID=${userID}&PlantID=${plantID}`);
  }

  // USER ACL INSERT/UPDATE //
  userACLInsertUpdate(aclData: any) {
    let userID = aclData.UserID;
    let plantID = aclData.PlantID;
    let desigID = aclData.DesignationID ? aclData.DesignationID : 0;
    let deptID = aclData.DeptID ? aclData.DeptID : 0;
    let menuList = aclData.MenuList;
    let isActive = aclData.IsActive;
    return this.http.get(`${USER_ACL_INSERT_UPDATE}?UserID=${userID}&PlantID=${plantID}&DesignationID=${desigID}&DeptID=${deptID}&MenuList=${menuList}&IsActive=${isActive}`);
  }

  // GET DEFAULT MENU TEMPLATE LIST//
  getDefaultMenuTemplateList(menuTempID: number) {
    return this.http.get(`${GET_MENU_TEMPLATE_LIST}?MenuTempID=${menuTempID}`);
  }

  // GET ACL LISTING //
  getACLListing(currPage: number, pageSize: number) {
    return this.http.get(`${GET_ACL_LISTING}?CurrentPage=${currPage}&PageSize=${pageSize}`);
  }

  // GET_ACL_BY_MENU_TEMPID //
  getACLByMenuTempID(menuTempID: number) {
    return this.http.get(`${GET_ACL_BY_MENU_TEMPID}?MenuTempID=${menuTempID}`);
  }

  // MENU TEMPLATE STATUS CHANGE //
  menuTemplateStatusChange(menuTempID: number) {
    return this.http.get(`${MENU_TEMPLATE_STATUS_CHANGE}?MenuTempID=${menuTempID}`);
  }

  // GET MENU LISTING //
  getMenuListing() {
    return this.http.get(`${GET_MENU_LISTING}`);
  }

  // MENU TEMPLATE INSERT UPDATE //
  menuTemplateInsertUpdate(aclData: any) {
    let menuTempID = aclData.MenuTempID;
    let templateName = aclData.TemplateName
    let menuArr = aclData.MenuArray
    return this.http.get(`${MENU_TEMPLATE_INSERT_UPDATE}?MenuTempID=${menuTempID}&TemplateName=${templateName}&MenuArray=${menuArr}`);
  }

}
