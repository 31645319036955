import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Helper } from './../_helper/default';
import { API_GET_TABLES, API_GET_TABLE_SCHEMA,API_GET_SAMPLE_MTD_YTD } from '../_common/constant';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private helper: Helper,
    private http: HttpClient) { }

  exampleFunc(xData: any) {
    return this.http.post('', xData, this.helper.getHeaders());
  }

  _getTableNames() {
    return this.http.get(API_GET_TABLES)
  }

  _getTableSchema(jsonData: any) {
    return this.http.get(API_GET_TABLE_SCHEMA + `?TableName=${jsonData.TableName}`);
  }

  _getSampleCollectedMTDYTD() {
    return this.http.get(API_GET_SAMPLE_MTD_YTD);
  }

}
